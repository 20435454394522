import { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import appSettings from '../../appSettings';

export async function loadSettings(): Promise<UserManagerSettings> {
  const config = await appSettings();
  const settings: UserManagerSettings = {
    authority: config.userManager.authority,
    client_id: config.userManager.client_id,
    redirect_uri: config.userManager.redirect_uri,
    response_type: 'code',
    scope: config.userManager.scope,
    post_logout_redirect_uri: config.userManager.post_logout_redirect_uri,
    silent_redirect_uri: config.userManager.silent_redirect_uri,
    automaticSilentRenew: true,
  };
  return {
    ...settings,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };
}
