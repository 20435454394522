import {
  configureStore,
  Action,
  ThunkAction as GenericThunkAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { loadUser } from "redux-oidc";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { routerMiddleware } from "connected-react-router";
import rootReducer, { history } from "./rootReducer";
import { getUserManager } from "../core/auth/userManager";

export { history };

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
    routerMiddleware(history),
  ],
});

getUserManager().then((um) => loadUser(store, um));

export type RootState = ReturnType<typeof rootReducer>;
export type Dispatch = ThunkDispatch<RootState, unknown, Action>;
export type ThunkAction<R = void> = GenericThunkAction<
  R,
  RootState,
  undefined,
  Action
>;
export type ThunkApi<TRejectValue = unknown> = {
  state: RootState;
  dispatch: Dispatch;
  extra?: unknown;
  rejectValue?: TRejectValue;
};
export const persistor = persistStore(store);
