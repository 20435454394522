import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { store } from '../../store/configureStore';

export function includeAuthInterceptor(axios: AxiosInstance) {
  axios.interceptors.request.use(
    (config: AxiosRequestConfig): AxiosRequestConfig => {
      const authState = store.getState().oidc;
      if (config.headers && authState.user) {
        config.headers.Authorization =
          authState.user.token_type + ' ' + authState.user.access_token;
      }
      return config;
    }
  );
}
