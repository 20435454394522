import { createAsyncThunk } from "@reduxjs/toolkit";
import { ItemModel, itemsClient } from "../../client/app";
import { ThunkApi } from "../configureStore";

export const loadItems = createAsyncThunk<ItemModel[], void, ThunkApi>(
  "items/load",
  async () => {
    const { data: items } = await itemsClient.getItems();
    return items;
  }
);
