import { useEffect, useState } from "react";
import { getUserManager } from "./core/auth/userManager";
import { FullScreenLoader } from "./components/FullScreenLoader";
import Dashboard from "./pages/dashboard";
import { Route, Switch } from "react-router";
import { Redirect } from "react-router";
import { Layout } from "./core/layout/Layout";
import './core/scss/style.scss';

function App() {
  return (
  <Layout>
     <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route path="/dashboard" component={Dashboard} exact />
        {/* <Route component={NotFound} /> */}
      </Switch>
  </Layout>
  );
}

function AuthenticatedApp() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      await getUserManager().then((um) => {
        um.getUser().then((user) => {
          const shouldRedirect = user === null || user.expired;
          shouldRedirect && um.signinRedirect();
          setIsLoggedIn(!shouldRedirect);
        });
      });
    };

    checkAuth();
  }, []);

  return isLoggedIn ? <App /> : <FullScreenLoader />;
}

export default AuthenticatedApp;
