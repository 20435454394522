import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboard } from "../../store";

const Dashboard = () => {
  const dispatch = useDispatch();
  const loading = useSelector(dashboard.selectors.isLoading);
  const items = useSelector(dashboard.selectors.items);

  useEffect(() => {
    dispatch(dashboard.thunks.loadItems());
  }, [dispatch]);

  return (
    <>
      {!loading && (
        <div>
          items:
          <ul>
            {items.map((item) => (
              <li key={item.id}>{item.name}</li>
            ))}
          </ul>
        </div>
      )}
      {loading && <div> Loading items</div>}
    </>
  );
};

export default Dashboard;
