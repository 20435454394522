import { createSlice } from '@reduxjs/toolkit';
import { DashboardState } from './types';
import { loadItems } from './thunks';

const initialState: DashboardState = {
  loading: false,
  items: []
};

export const { actions, reducer } = createSlice({
  name: 'requests',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(loadItems.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadItems.fulfilled, (state, action) => {
      state.loading = false;
      state.items = action.payload;
    });
    builder.addCase(loadItems.rejected, (state) => {
      state.loading = false;
    });
  },
});
