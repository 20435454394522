import React from 'react';
import { useAsync, IfFulfilled } from 'react-async';
import { getUserManager } from './userManager';
import { RouteComponentProps } from 'react-router';
import { CallbackComponent, loadUser } from 'redux-oidc';
import { store } from '../../store/configureStore';
import { FullScreenLoader } from '../../components/FullScreenLoader';

export const OidcCallback = ({ history }: RouteComponentProps) => {
  const state = useAsync({ promiseFn: getUserManager });

  return (
    <IfFulfilled state={state}>
      {(data) => (
        <CallbackComponent
          userManager={data}
          successCallback={() => {
            loadUser(store, data);
            history.push('/');
          }}
          errorCallback={(error) => {
            console.error(error);
          }}
        >
          <FullScreenLoader />
        </CallbackComponent>
      )}
    </IfFulfilled>
  );
};
