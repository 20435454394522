import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { cilLockUnlocked } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useDispatch, useSelector } from "../../hooks";
import { NavLink } from "react-router-dom";

export const AppHeaderDropdown = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.oidc.user);

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle className="py-0" caret={false}>
        {user && user.profile.email}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem href="/logout">
          <CIcon icon={cilLockUnlocked} className="me-2" />
            Wyloguj
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};
