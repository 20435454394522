import { layout } from "../../../store/layout";
import { useDispatch, useSelector } from "../../hooks";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { AppSidebarNav } from "./AppSidebarNav";
import navigation from "../../../_nav";

export const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.layout.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.layout.sidebarShow);

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(layout.actions.sidebarShow(visible));
      }}
    >
      <CSidebarBrand className="d-none d-md-flex">
        {unfoldable ? "PC" : "PRICE SCRAPER"}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(layout.actions.toggleSidebarUnfoldable())}
      />
    </CSidebar>
  );
};
