import { CSpinner } from "@coreui/react";
import styled from "@emotion/styled";

const S = {
  CenteredDiv: styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
  `,
};

export const FullScreenLoader = () => (
  <S.CenteredDiv>
    <CSpinner color="dark" />
  </S.CenteredDiv>
);
