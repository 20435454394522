import { useEffect } from 'react';

import { getUserManager } from './userManager';
import { FullScreenLoader } from '../../components/FullScreenLoader';

export const OidcLogout = () => {
  useEffect(() => {
    getUserManager().then((um) => um.signoutRedirect());
  }, []);

  return <FullScreenLoader />;
};
