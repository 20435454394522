import React, { Suspense } from "react";
import { AppSidebar } from "./components/AppSidebar";
import { AppHeader } from "./components/AppHeader";
import { AppFooter } from "./components/AppFooter";
import { CContainer, CSpinner } from "@coreui/react";

export const Layout = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <CContainer lg>
            <Suspense fallback={<CSpinner color="primary" />}>
              {children}
            </Suspense>
          </CContainer>
        </div>
        <AppFooter />
      </div>
    </>
  );
};
