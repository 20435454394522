import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reducer as oidcReducer } from 'redux-oidc';
import { dashboard, layout } from '.';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  oidc: oidcReducer,
  dashboard: dashboard.reducer,
  layout: layout.reducer
});

export default rootReducer;
