import { CFooter } from "@coreui/react";
import appSettings from "../../../appSettings";
import { IfFulfilled, useAsync } from "react-async";

export const AppFooter = () => {
  const state = useAsync({ promiseFn: appSettings });

  return (
    <CFooter>
      <div className="ms-auto">
        <IfFulfilled state={state}>
          {(settings) => <span>Wersja: {settings.appVersion}</span>}
        </IfFulfilled>
      </div>
    </CFooter>
  );
};
