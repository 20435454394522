import { ConnectedRouter } from "connected-react-router";
import { IfFulfilled, useAsync } from "react-async";
import { Provider, ReactReduxContext } from "react-redux";
import { OidcProvider } from "redux-oidc";
import { OidcCallback } from "./core/auth/OidcCallback";
import { OidcLogout } from "./core/auth/OidcLogout";
import { getUserManager } from "./core/auth/userManager";
import { store, history } from "./store/configureStore";
import { Route, Switch } from "react-router";
import App from "./App";

function AppProvider() {
  const state = useAsync({ promiseFn: getUserManager });

  return (
    <Provider store={store} context={ReactReduxContext}>
      <IfFulfilled state={state}>
        {(userManager) => (
          <OidcProvider store={store} userManager={userManager}>
            <ConnectedRouter history={history} context={ReactReduxContext}>
              <Switch>
                <Route path="/callback" component={OidcCallback} />
                <Route path="/logout" component={OidcLogout} />
                <App />
              </Switch>
            </ConnectedRouter>
          </OidcProvider>
        )}
      </IfFulfilled>
    </Provider>
  );
}

export default AppProvider;
