import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { getUserManager } from '../auth/userManager';

export function include401UnauthorizedInterceptor(axios: AxiosInstance) {
  axios.interceptors.response.use(
    (resp: AxiosResponse<any>) => resp,
    async (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        await getUserManager().then((um) => um.signinRedirect());
      } else {
        return Promise.reject(error);
      }
    }
  );
}
