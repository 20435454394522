import { Settings } from './appSettings.model';

const appSettings = async (): Promise<Settings> => {
  const appSettings = await loadAppSettings(
    '/appsettings' + (process.env.REACT_APP_ENV ? '.' + process.env.REACT_APP_ENV : '') + '.json'
  );

  const config = {
    appVersion: appSettings.appVersion,
    userManager: appSettings.userManager,
  };

  if (config.appVersion.indexOf('#') === 0) {
    config.appVersion = '0.0.0';
  }

  if (config.userManager.authority?.indexOf('#') === 0) {
    config.userManager.authority = 'https://localhost:4976';
    config.userManager.redirect_uri = 'http://localhost:4977/callback';
    config.userManager.post_logout_redirect_uri = 'http://localhost:4977/';
    config.userManager.silent_redirect_uri = 'http://localhost:4977/silent-redirect.html';
  }

  (window as any).version = config.appVersion;

  return config;
};

export default appSettings;

async function loadAppSettings(filename: string): Promise<Settings> {
  const response = await fetch(filename);
  const json = await response.json();
  return (json as unknown) as Settings;
}
