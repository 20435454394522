import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "../../hooks";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilMenu } from "@coreui/icons";
import { layout } from "../../../store";
import { AppHeaderDropdown } from "./AppHeaderDropdown";

export const AppHeader = () => {
  const dispatch = useDispatch();

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch(layout.actions.toggleSidebarShow())}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderNav className="d-none d-md-flex me-auto"></CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      {/* <CHeaderDivider />
      <CContainer fluid><AppBreadcrumb /></CContainer> */}
    </CHeader>
  );
};
