import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
};

export const { actions, reducer } = createSlice({
  name: "layout",
  initialState,
  reducers: {
    sidebarShow(state, action: PayloadAction<boolean>) {
      state.sidebarShow = action.payload;
    },
    toggleSidebarShow(state) {
      state.sidebarShow = !state.sidebarShow;
    },
    toggleSidebarUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
  },
});
